<template>
  <div class="d-block">
    <v-dialog
      v-model="dialog"
      :hide-overlay="loading"
      :persistent="loading"
      width="600"
    >
      <v-card
        :color="loading ? 'primary':''" 
        :dark="loading"
      >
        <!-- Loading -->
        <v-card-text v-if="loading">
          <span class="mt-2 mb-2 font-weight-bold">Please wait a minute</span>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
        <!-- Is value -->
        <v-card-text v-else>
          <v-card-title>
            <span class="primary--text">Create new invoice - Result</span>
          </v-card-title>
          <div class=" d-block w-100" v-if="dataCreateNewInvoice.object != null">
            <v-list
             two-line
            >
              <template v-for="(itemObject, index) in dataCreateNewInvoice.object">
                <v-list-item :key="`Title-${index}`">
                  <v-list-item-content>
                    <v-list-item-title v-html="index"></v-list-item-title>
                    
                    <v-list-item-subtitle v-if="index != 'invoiceGUID'" class="mt-1 font-weight-black" v-html="itemObject != '' ? itemObject : 'Empty'"></v-list-item-subtitle>
                    <v-list-item-subtitle v-else>
                      <a :href="`/invoice/view-invoice-detail-by-invoice-guid/${itemObject}`" 
                        v-if="dataCreateNewInvoice.status == 0">
                        {{itemObject}}
                      </a>
                      <span v-else>Empty</span>
                    </v-list-item-subtitle>
              
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  v-if="index != 'MessLog'"
                  :key="index"
                ></v-divider>
              </template>
            </v-list>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-form
      ref="formContainer"
      v-model="validForm"
      >
      <v-card elevation="0" outlined>
        <v-card-title>
          <span class="font-weight-bold">Create a common invoice</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-row class="pa-0">
                <v-col cols="2" class="d-flex align-center">
                  <label class="font-weight-bold text-subtitle-2">Chọn ngày xuất HĐ:</label>
                </v-col>
                <v-col cols="3">
                  <v-menu
                    v-model="openPickDate"
                    :close-on-content-click="false"
                    max-width="290"
                    >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="dateExportInvoice"
                        clearable
                        label="Pick date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="dateExportInvoice = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateExportInvoice"
                      @change="openPickDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="mt-2">
              <CreationForm 
                ref="CreationInvoiceForm"
                :batchInvoice=true
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <div class="d-block w-100" >
        <div class="footer__creation__form">
          <div class="footer__creation__action">
            <v-btn color="primary"
              @click="saveDataForm"
              :disabled="loading"
              :loading="loading"
              >
              Create new
            </v-btn>

            <v-btn 
              color="warning" 
              class="ml-1"
              :disabled="loading"
              @click="resetAllToDefault"
              >
              Reset all
            </v-btn>
          </div>
          
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import moment from "moment";
import InvoiceService  from '@/services/invoice'
import CreationForm from './CreatationForm'
export default {
  name: 'CreateNewBatchInvoiceComponent',
  components: {
    CreationForm
  },
  data: () => {
    return {
      validForm: true,
      loading: false, 
      dataInsert: {},
      dialog: false,
      dataCreateNewInvoice: {
        isError: false,
        isOk: false,
        error_messages: '',
        object: null,
        status: 0
      },
      dateExportInvoice: null,
      openPickDate: false
    }
  },
  created() {
    this.dateExportInvoice = moment().format('YYYY-MM-DD')
  },
  methods: {
    async saveDataForm() {
      if (this.$refs.formContainer.validate()) {
        this.loading = true;
        this.dialog = true;
        this.$refs.CreationInvoiceForm.disableDiv(true)
        let data = this.$refs.CreationInvoiceForm.emitRealDataForCreation();
        
        var createBatchInvoice = await InvoiceService.createBatchInvoice(data, this.dateExportInvoice);
        if (createBatchInvoice.error) {
          this.loading = false;
          this.dialog = false;
          this.$refs.CreationInvoiceForm.disableDiv(false)
          this.$toast.error("Something went wrong when try to action!");
          this.dataCreateNewInvoice = {
            isError: false,
            isOk: false,
            error_messages: '',
            object: null,
            status: 0
          }
          return;
        }
        this.loading = false;
        this.$refs.CreationInvoiceForm.disableDiv(false)
        
        this.dataCreateNewInvoice = {
          isError: createBatchInvoice.isError,
          isOk:  createBatchInvoice.isOk,
          object: createBatchInvoice.object,
          status: createBatchInvoice.status,
        }
      } else {
        this.$toast.error('Some input need be filled!')
      }
    },
    resetAllToDefault() {
       
      this.$refs.CreationInvoiceForm.resetListInputToDefault();
    }
  }
}
</script>

<style scoped>
  .footer__creation__form {
    width: inherit;
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 5px 0px 5px 0px;
    max-width: inherit;
    display: flex;
    justify-content: center;
    z-index: 99;
    backdrop-filter: blur(2px);
    background: rgba(207, 207, 207, 0.75);
  }
  .creation__container {
    width: 100%;
  }
  .footer__creation__form {
    padding: 5px;
    z-index:100;
    height: inherit;
  }
</style>